import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import type { OnDestroy } from '@angular/core';
import {
  Inject,
  inject,
  Injectable,
  PLATFORM_ID,
  RendererFactory2,
} from '@angular/core';
import { ABTest } from '@freelancer/abtest';
import { ActivatedRoute } from '@freelancer/activated-route';
import { Auth } from '@freelancer/auth';
import { LocalStorage } from '@freelancer/local-storage';
import { UI_CONFIG } from '@freelancer/ui/ui.config';
import { UiConfig } from '@freelancer/ui/ui.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Observable } from 'rxjs';
import {
  combineLatest,
  firstValueFrom,
  fromEvent,
  of,
  Subscription,
} from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { ShellConfig } from '../../app/app-shell/shell-config.service';
import type { Theme, ThemeSetting } from './theme.types';

@UntilDestroy({ className: 'ThemeService' })
@Injectable({
  providedIn: 'root',
})
export class ThemeService implements OnDestroy {
  private document = inject(DOCUMENT);
  private localStorage = inject(LocalStorage);
  private platformId = inject(PLATFORM_ID);
  private renderer = inject(RendererFactory2).createRenderer(null, null);

  private readonly DARK_MODE_MEDIA_QUERY = '(prefers-color-scheme: dark)';

  /**
   * themeSetting is the actual setting that the user has chosen (or been defaulted to).
   * This can be: 'light' | 'dark' | 'system'. This setting is stored in local storage.
   */
  private readonly themeSetting$: Observable<ThemeSetting>;
  /**
   * Theme is the actual theme being used in the app. This is either 'light' or 'dark'.
   * If the themeSetting is 'system', then the theme will be 'light' or 'dark' based on the user's OS settings.
   */
  private readonly theme$: Observable<Theme>;
  private subscription = new Subscription();

  constructor(
    activatedRoute: ActivatedRoute,
    auth: Auth,
    private abtest: ABTest,
    shellConfig: ShellConfig,
    @Inject(UI_CONFIG) private uiConfig: UiConfig,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.themeSetting$ = combineLatest([
        activatedRoute.queryParams,
        shellConfig.getConfig(activatedRoute.activatedRoute),
        auth.isLoggedIn(),
        this.localStorage.get('theme'),
      ]).pipe(
        switchMap(
          ([
            queryParams,
            config,
            isLoggedIn,
            theme,
          ]): Observable<ThemeSetting> => {
            // Check for specific themes first (priority over everything)
            if (
              this.uiConfig.theme === 'deloitte' ||
              this.uiConfig.theme === 'freightlancer'
            ) {
              return of('light');
            }

            if (config.forceDarkMode) {
              return of('dark');
            }

            // Allow user to override dark mode if they've selected a theme manually
            if (theme) {
              return of(theme); // If a user has logged in before but hasn't explicitly set their theme (so they currently are defaulting to light), is the localstorage item undefined.
            }

            if (!isLoggedIn) {
              return of('light');
            }

            // Check if the user is in the whitelist
            if (this.abtest.isWhitelistUser()) {
              return of('system');
            }

            // Fallback to A/B test logic for logged-in users only
            return this.abtest
              .getUserExperimentVariation('T307094-enforce-system-mode')
              .pipe(
                map(abTestVariation => {
                  if (abTestVariation === 'test') {
                    // Use setThemeSetting to save dark mode preference
                    this.setThemeSetting('system');
                    return 'system'; // Enforce dark mode for A/B test participants
                  }

                  // Fallback to queryParams logic for logged-in users
                  return queryParams.showDarkDefaultShadow === 't'
                    ? 'system'
                    : 'light';
                }),
              );
          },
        ),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

      // Listen for changes to the system theme.
      this.subscription.add(
        fromEvent(
          window.matchMedia(this.DARK_MODE_MEDIA_QUERY),
          'change',
        ).subscribe(async event => {
          if (!('matches' in event)) {
            return;
          }

          const setting = await firstValueFrom(
            this.themeSetting$.pipe(untilDestroyed(this)),
          );

          if (setting === 'system') {
            this.applyTheme(event.matches ? 'dark' : 'light');
          }
        }),
      );

      // The actual theme being used in the app.
      // Derived from the themeSetting and the system theme.
      this.theme$ = this.themeSetting$.pipe(
        map(theme =>
          theme === 'system'
            ? window.matchMedia(this.DARK_MODE_MEDIA_QUERY).matches
              ? 'dark'
              : 'light'
            : theme,
        ),
      );
    } else {
      this.themeSetting$ = of('light');
      this.theme$ = of('light');
    }
  }

  getThemeSetting(): Observable<ThemeSetting> {
    return this.themeSetting$;
  }

  getTheme(): Observable<Theme> {
    return this.theme$;
  }

  async setThemeSetting(theme: ThemeSetting): Promise<void> {
    await this.localStorage.set('theme', theme);
  }

  applyTheme(theme: Theme): void {
    if (isPlatformBrowser(this.platformId)) {
      const docElement = this.document.documentElement;
      if (theme === 'dark') {
        this.renderer.addClass(docElement, 'dark');
      } else {
        this.renderer.removeClass(docElement, 'dark');
      }
    }
  }

  initializeTheme(theme: Theme): void {
    this.applyTheme(theme);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
