import { isPlatformServer } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import type { OnDestroy } from '@angular/core';
import {
  Inject,
  Injectable,
  LOCALE_ID,
  NgZone,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { Auth } from '@freelancer/auth';
import { RepetitiveSubscription } from '@freelancer/decorators';
import type {
  AppVersion,
  FreelancerPwaTrackingInterface,
  NativeDeviceInfo,
  Platform,
} from '@freelancer/pwa';
import { Pwa } from '@freelancer/pwa';
import type { Timer } from '@freelancer/time-utils';
import { TimeUtils, leaveZone } from '@freelancer/time-utils';
import { UserAgent } from '@freelancer/user-agent';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Request, Response } from 'express';
import { CookieService } from 'ngx-cookie';
import type { Observable } from 'rxjs';
import {
  Subject,
  Subscription,
  asyncScheduler,
  firstValueFrom,
  of,
} from 'rxjs';
import {
  buffer,
  debounceTime,
  map,
  observeOn,
  switchMap,
} from 'rxjs/operators';
import { REQUEST, RESPONSE } from '../../express.tokens';
import { AdswapperTracking } from './adswapper-tracking';
import { BingAdsTracking } from './bing-ads-tracking.service';
import type { FacebookStandardEvents } from './facebook-pixel-tracking.service';
import { FacebookPixelTracking } from './facebook-pixel-tracking.service';
import { GoogleTracking } from './google-tracking.service';
import type { TrackingExtraParams } from './interface';
import { TrackingConsentStatus } from './interface';
import type { LinkedInTrackingEvent } from './linkedIn-pixel-tracking.service';
import { LinkedInPixelTracking } from './linkedIn-pixel-tracking.service';
import { MgidTracking } from './mgid-tracking.service';
import { OutbrainPixelTracking } from './outbrain-pixel-tracking.service';
import type { QuoraStandardEvents } from './quora-pixel-tracking.service';
import { QuoraPixelTracking } from './quora-pixel-tracking.service';
import type { RedditStandardEvents } from './reddit-pixel-tracking.service';
import { RedditPixelTracking } from './reddit-pixel-tracking.service';
import { TaboolaPixelTracking } from './taboola-pixel-tracking.service';
import type {
  TiktokCustomEvents,
  TiktokStandardEvents,
} from './tiktok-pixel-tracking.service';
import { TiktokPixelTracking } from './tiktok-pixel-tracking.service';
import { TrackingConsent } from './tracking-consent.service';
import { TRACKING_CONFIG } from './tracking.config';
import { TrackingConfig } from './tracking.interface';
import type {
  ConversionData,
  CustomTrackingEvent,
  HeartbeatTrackingCancellation,
  TrackingEvent,
  TrackingEventData,
  TrackingEventType,
} from './tracking.model';

export { BingAdsTracking } from './bing-ads-tracking.service';

export interface TrackingBeacon {
  beaconId: string;
  payload: TrackingEvent & TrackingEventData;
}

export interface PixelParams {
  facebookEventName?: FacebookStandardEvents;
  mgidButtonId?: string;
  triggerAdswapper?: boolean;
  linkedInEventData?: LinkedInTrackingEvent;
  quoraEventName?: QuoraStandardEvents;
  tiktokEventName?: TiktokStandardEvents | TiktokCustomEvents;
  taboolaEventName?: string;
  redditEventName?: RedditStandardEvents | string;
}

@UntilDestroy({ className: 'Tracking' })
@Injectable({
  providedIn: 'root',
})
export class Tracking implements FreelancerPwaTrackingInterface, OnDestroy {
  private sessionId: string;
  /** Unique to each tab, to enable identifying individual tabs for users. */
  private clientId: string;
  private referrerUrl: string;
  private platform: Platform;
  private isNative: boolean;
  private isInstalled: boolean;
  private appVersion?: AppVersion;
  private nativeDeviceInfo?: NativeDeviceInfo;
  private eventsSubject$ = new Subject<TrackingBeacon>();
  private events$: Observable<TrackingBeacon> =
    this.eventsSubject$.asObservable();
  private initPromise?: Promise<void>;
  @RepetitiveSubscription()
  private eventSubscription?: Subscription;

  constructor(
    private auth: Auth,
    private cookies: CookieService,
    private http: HttpClient,
    private mgidTracking: MgidTracking,
    private ngZone: NgZone,
    private pwa: Pwa,
    private timeUtils: TimeUtils,
    private trackingConsent: TrackingConsent,
    private userAgent: UserAgent,
    private adswapperTracking: AdswapperTracking,
    private bingAdsTracking: BingAdsTracking,
    private facebookPixelTracking: FacebookPixelTracking,
    private googleTracking: GoogleTracking,
    private linkedInPixelTracking: LinkedInPixelTracking,
    private outbrainPixelTracking: OutbrainPixelTracking,
    private quoraPixelTracking: QuoraPixelTracking,
    private redditPixelTracking: RedditPixelTracking,
    private taboolaPixelTracking: TaboolaPixelTracking,
    private tiktokPixelTracking: TiktokPixelTracking,
    @Inject(LOCALE_ID) private locale: string,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(TRACKING_CONFIG) private config: TrackingConfig,
    @Optional() @Inject(REQUEST) private request: Request,
    @Optional() @Inject(RESPONSE) private response: Response,
  ) {}

  // DO NOT CALL THAT FROM THE app/ CODE. IT'LL BE RESTRICTED SOON.
  async track(
    eventType: TrackingEventType,
    eventData: TrackingEventData = {},
    pixelParams: PixelParams = {},
  ): Promise<void> {
    if (isPlatformServer(this.platformId)) {
      throw new Error('Client-site tracking cannot be used on the server-side');
    }
    await this.init();

    return firstValueFrom(this.auth.authState$.pipe(untilDestroyed(this))).then(
      auth => {
        const e: TrackingEvent = {
          ...{
            en: eventType,
            acct: window.location.hostname.replace(/^www/, ''),
            platform: this.platform,
            isNative: this.isNative,
            isInstalled: this.isInstalled,
            appVersion: this.appVersion,
            nativeDeviceInfo: this.nativeDeviceInfo,
            t: Date.now().toString(),
            location: window.location.href,
            user_id: auth ? parseInt(auth.userId, 10) : undefined,
            session_id: this.sessionId,
          },
          ...eventData,
          ...pixelParams,
        };
        return this.sendBeacon(e);
      },
    );
  }

  getSessionId(): Observable<string> {
    // sessionId could eventually be turned into a true observable
    return of(undefined).pipe(
      switchMap(async () => {
        await this.init();
      }),
      map(() => this.sessionId),
    );
  }

  bypassCachingInitServerSideSessionId(): void {
    if (isPlatformServer(this.platformId) && !this.sessionId) {
      if (
        this.request.cookies &&
        this.request.cookies[this.config.sessionCookie]
      ) {
        this.sessionId = this.request.cookies[this.config.sessionCookie];
      } else {
        const sessionId = this.generateUuid();
        this.response.cookie(this.config.sessionCookie, sessionId);
        this.sessionId = sessionId;
      }
      this.response.set('Cache-Control', 'no-store, no-cache');
    }
  }

  async trackPageView(is404?: boolean): Promise<void> {
    await this.init();

    this.track('page_view', {
      language: this.locale,
      referrer_url: this.referrerUrl,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      windowInnerHeight: window.innerHeight,
      windowInnerWidth: window.innerWidth,
      is_404: is404,
    });
    // update the referrerUrl so that the next page_view event contains it.
    this.referrerUrl = window.location.href;
  }

  /**
   * Track memory usage
   */
  async trackMemoryProfiling(values: {
    reason: 'heartbeat';
    jsHeapSizeLimit: number;
    totalJSHeapSize: number;
    usedJSHeapSize: number;
    timeSinceWebappLoaded: number;
  }): Promise<void> {
    await this.init();

    this.track('webapp_prof', { section: 'memory', ...values });
  }

  async trackCustomEvent(
    name: string,
    section?: string,
    extraParams?: TrackingExtraParams,
    pixelParams?: PixelParams,
  ): Promise<void> {
    await this.init();

    this.track('custom_event', {
      name,
      section,
      extra_params: {
        ...extraParams,
        client_id: this.clientId,
      },
      ...pixelParams,
    });
  }

  async trackHeartbeat(
    name: string,
    referenceType?: string,
    referenceId?: string,
  ): Promise<HeartbeatTrackingCancellation> {
    await this.init();

    const startTime = Date.now();
    let count = 0;
    let interval = 1000;
    let timeoutId: Timer;

    const track = (): void => {
      count += 1;
      this.track('heart_beat', {
        name,
        start_time: startTime,
        count,
        reference: referenceType,
        reference_id: referenceId,
      });
      interval = Math.round(interval * 1.15);
      timeoutId = this.timeUtils.setTimeout(() => track(), interval);
    };

    track();

    return () => {
      clearTimeout(timeoutId);
    };
  }

  async trackSearch({
    filters,
    itemsCount,
    itemsDisplayed,
    itemsPage,
    keyword = '',
    section,
    sort,
    source,
  }: {
    readonly filters: {
      [k: string]: any;
    };
    readonly itemsCount: number;
    readonly itemsDisplayed: number;
    readonly itemsPage: number;
    readonly keyword?: string;
    readonly section: string;
    readonly sort?: string;
    readonly source: string;
  }): Promise<void> {
    await this.init();

    this.track('search', {
      filters,
      items_count: itemsCount,
      items_displayed: itemsDisplayed,
      items_page: itemsPage,
      keyword,
      section,
      sort,
      source,
    });
  }

  // Holds conversion tracking implementations per tracking service
  // Currently we're only using Google conversion tracking.
  //
  // Usage:
  //   trackConversion({ google: some-conversion-id });
  async trackConversion(conversion: ConversionData): Promise<void> {
    await this.init();

    if (conversion.google) {
      this.googleTracking.trackConversion(conversion.google);
    }
  }

  // Only to be called by the Tracking component
  async sendEventBacklog(): Promise<void> {
    await this.init();

    // send the event backlog if any
    try {
      const backlog = localStorage.getItem('trackingBacklog');
      if (backlog) {
        const itemsMap: { [items: string]: TrackingEvent } =
          JSON.parse(backlog);
        Object.entries(itemsMap).forEach(([beaconId, payload]) => {
          this.eventsSubject$.next({
            beaconId,
            payload,
          });
        });
      }
      localStorage.setItem('trackingBacklog', JSON.stringify({}));
    } catch (e: any) {
      // ignore the errors, e.g. quota is full or security error
      console.error(e);
    }
  }

  private async init(): Promise<void> {
    if (this.initPromise) {
      return this.initPromise;
    }

    this.initPromise = Promise.resolve().then(async () => {
      if (isPlatformServer(this.platformId)) {
        if (!this.sessionId) {
          throw new Error(
            'Session ID cannot be used on the server-side unless `bypassCachingInitServerSideSessionId()` is first called',
          );
        }
        return;
      }
      let sessionId = this.cookies.get(this.config.sessionCookie);
      if (!sessionId) {
        // Generate a new one if not.
        sessionId = this.generateUuid();
        this.cookies.put(this.config.sessionCookie, sessionId, {
          expires: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1),
          ),
        });
      }
      this.sessionId = sessionId;

      if (!this.cookies.get(this.config.linkedSessionCookie)) {
        firstValueFrom(
          this.http
            .post(
              this.config.linkSessionsEndpoint,
              new Blob([`tracking_session_id=${this.sessionId}`], {
                type: 'application/x-www-form-urlencoded',
              }),
              { withCredentials: true },
            )
            .pipe(untilDestroyed(this)),
        ).then(() => {
          this.cookies.put(this.config.linkedSessionCookie, 'linked', {
            expires: new Date(
              new Date().setFullYear(new Date().getFullYear() + 1),
            ),
          });
        });
      }

      let clientId: string | null | undefined;
      try {
        clientId = window.sessionStorage.getItem(this.config.clientKey);
      } catch (e: any) {
        // ignore the errors, e.g. quota is full or security error
        console.error(e);
      }
      if (!clientId) {
        clientId = this.generateUuid();
        try {
          window.sessionStorage.setItem(this.config.clientKey, clientId);
        } catch (e: any) {
          // ignore the errors, e.g. quota is full or security error
          console.error(e);
        }
      }
      this.clientId = clientId;

      // set the external referrer on start
      this.referrerUrl = document.referrer;

      // the mode the app runs in
      this.platform = this.pwa.getPlatform();
      this.isNative = this.pwa.isNative();
      this.isInstalled = this.pwa.isInstalled();
      this.appVersion = await firstValueFrom(
        this.pwa.appVersion().pipe(untilDestroyed(this)),
      );

      if (this.pwa.isNative()) {
        this.nativeDeviceInfo = await firstValueFrom(
          this.pwa.nativeDeviceInfo().pipe(untilDestroyed(this)),
        );
      }

      this.eventSubscription = this.events$
        .pipe(
          observeOn(leaveZone(this.ngZone, asyncScheduler)),
          // buffer the events for a 1s rolling window to limit the number of
          // requests
          // FIXME: T229388 - decreased to 100ms to test if it fixes T229388
          buffer(
            this.events$.pipe(
              // This is not switched across to timeUtils as it may break some PJP
              // draft tests.
              // eslint-disable-next-line local-rules/validate-timers
              debounceTime(100, leaveZone(this.ngZone, asyncScheduler)),
            ),
          ),
          switchMap(beacons => {
            const { name = '', version = '' } = this.userAgent
              .getUserAgent()
              .getBrowser();
            return 'sendBeacon' in navigator &&
              /**
               * T249140: Chrome v81 and below are not able to send POST
               * requests with arbitrary content type.
               *
               * For more details:
               * 1. https://bugs.chromium.org/p/chromium/issues/detail?id=490015
               * 2. https://stackoverflow.com/questions/45274021/
               */
              !(
                ['Chromium', 'Chrome', 'Chrome WebView'].includes(name) &&
                parseInt(version, 10) <= 81
              ) &&
              // sendBeacon() returns false if the user agent fails to queue the
              // data for transfer (e.g. quota reached), falling back to
              // http.post() here.
              navigator.sendBeacon(
                this.config.trackingEndpoint,
                new Blob([JSON.stringify(beacons.map(b => b.payload))], {
                  type: 'application/json',
                }),
              )
              ? of(beacons.map(b => b.beaconId))
              : this.http
                  .post(
                    this.config.trackingEndpoint,
                    beacons.map(b => b.payload),
                  )
                  .pipe(map(() => beacons.map(b => b.beaconId)));
          }),
        )
        .subscribe({
          next: beaconIds => {
            // Remove the event from the backlog once it has been sent
            try {
              const backlog = JSON.parse(
                (localStorage.getItem('trackingBacklog') as string) || '{}',
              );
              beaconIds.forEach(beaconId => {
                delete backlog[beaconId];
              });
              localStorage.setItem('trackingBacklog', JSON.stringify(backlog));
            } catch (e: any) {
              // ignore the errors, e.g. quota is full or security error
              console.error(e);
            }
          },
          error: (err: Error) => {
            if (!(err instanceof HttpErrorResponse)) {
              throw err;
            }

            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              throw err.error;
            } else if (err.status === 0) {
              // HTTP status 0 means that the request was blocked by the client
              // (browser), which is often due to an Ad blocker.
              console.error(err.message);
            } else {
              // The backend returned an unsuccessful response code
              throw err.message;
            }
          },
        });
    });
    return this.initPromise;
  }

  private generateUuid(): string {
    const s4 = (): string =>
      Math.floor((1 + Math.random()) * 0x1_00_00)
        .toString(16)
        .substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  private async sendBeacon(
    payload: TrackingEvent & TrackingEventData,
  ): Promise<void> {
    const beaconId = Date.now().toString();
    // save the event for later in case of a hard navigation
    try {
      const backlog = JSON.parse(
        (localStorage.getItem('trackingBacklog') as string) || '{}',
      );
      backlog[beaconId] = payload;
      localStorage.setItem('trackingBacklog', JSON.stringify(backlog));
    } catch (e: any) {
      // ignore the errors, e.g. quota is full or security error
      console.error(e);
    }

    // Add event to the batch queue
    this.eventsSubject$.next({
      beaconId,
      payload,
    });

    if (
      (await this.trackingConsent.getThirdPartyStatus()) ===
      TrackingConsentStatus.AUTHORIZED
    ) {
      // if third party tracking is allowed, send it when the page is done rendering
      this.ngZone.runOutsideAngular(() => {
        requestIdleCallback(() => {
          // if GA tracking is enabled, track the click event there
          if (this.config.gaTrackingId && payload.action === 'click') {
            this.googleTracking.trackClickEvent(payload);
          }

          if (payload.en === 'page_view') {
            // if GA tracking is enabled, track the page view there as well
            if (this.config.gaTrackingId) {
              this.googleTracking.trackPageView();
            }
            // if the FB pixel is enabled, track the page view there as well
            if (this.config.facebookPixelId) {
              this.facebookPixelTracking.trackPageView();
            }

            // if the LinkedIn pixel is enabled, track the page view there as well
            if (this.config.linkedInPixelId) {
              this.linkedInPixelTracking.trackPageView();
            }

            // if the Quora pixel is enabled, track the page view there as well
            if (this.config.quoraPixelId) {
              this.quoraPixelTracking.trackPageView();
            }

            if (
              this.config.tiktokPixelIdMap.australiaNewZealand &&
              this.config.tiktokPixelIdMap.canadaAmerica
            ) {
              this.tiktokPixelTracking.trackPageView();
            }

            // if the Bing Ads tracking is enabled, track the page view there as well
            if (this.config.bingAdsTagId) {
              this.bingAdsTracking.trackPageView();
            }

            // if the Outbrain pixel is enabled, track the page view there as well
            if (this.config.outbrainPixelId) {
              this.outbrainPixelTracking.trackPageView();
            }

            // if the Taboola pixel is enabled, track the page view there as well
            if (this.config.taboolaPixelId) {
              this.taboolaPixelTracking.trackPageView();
            }
            // if the Reddit pixel is enabled, track the page view there as well
            if (this.config.redditPixelId) {
              this.redditPixelTracking.trackPageView();
            }

            this.adswapperTracking.init();
            this.mgidTracking.loadTrackingSnippet();
          }

          // Forward custom event to Google and Facebook tracking.
          // Custom event name is required for a custom event.
          if (this.isCustomTrackingEvent(payload)) {
            if (this.config.gaTrackingId) {
              this.googleTracking.trackCustomEvent(payload);

              if (payload.extra_params && payload.extra_params.pagePath) {
                this.googleTracking.virtualPageView(
                  payload.extra_params.pageTitle,
                  payload.extra_params.pagePath,
                );
              }
            }
            if (this.config.facebookPixelId) {
              this.facebookPixelTracking.trackCustomEvent(payload);
            }
            if (payload.mgidButtonId) {
              this.mgidTracking.trackCustomEvent(payload.mgidButtonId);
            }
            if (payload.triggerAdswapper) {
              this.adswapperTracking.trackCustomEvent();
            }
            if (this.config.linkedInPixelId) {
              this.linkedInPixelTracking.trackCustomEvent(payload);
            }
            if (this.config.quoraPixelId && payload.quoraEventName) {
              this.quoraPixelTracking.trackCustomEvent(payload);
            }
            if (
              this.config.tiktokPixelIdMap.australiaNewZealand &&
              this.config.tiktokPixelIdMap.canadaAmerica
            ) {
              this.tiktokPixelTracking.trackCustomEvent(payload);
            }
            if (this.config.outbrainPixelId) {
              this.outbrainPixelTracking.trackCustomEvent(payload);
            }
            if (this.config.taboolaPixelId) {
              this.taboolaPixelTracking.trackCustomEvent(payload);
            }
            if (this.config.redditPixelId) {
              this.redditPixelTracking.trackCustomEvent(payload);
            }

            if (this.config.bingAdsTagId) {
              this.bingAdsTracking.trackCustomEvent(payload);
            }
          }
        });
      });
    }
  }

  getTrackingHeaders(): Observable<HttpHeaders> {
    if (isPlatformServer(this.platformId)) {
      return of(new HttpHeaders());
    }

    return this.getSessionId().pipe(
      map(sessionId => new HttpHeaders().set('Freelancer-Tracking', sessionId)),
    );
  }

  ngOnDestroy(): void {
    this.eventSubscription?.unsubscribe();
  }

  // Type guard for custom event
  private isCustomTrackingEvent(
    event: TrackingEvent & TrackingEventData,
  ): event is CustomTrackingEvent & TrackingEventData {
    return (
      (event as CustomTrackingEvent & TrackingEventData).en === 'custom_event'
    );
  }
}
